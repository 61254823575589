import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAuthHeaders } from '../../../../Auth';

const pageSize = 10;

const locationsSlice = createSlice({
  name: 'locations',
  initialState: {
    searchTerm: undefined,
    sort: undefined,
    loading: false,
    totalCount: undefined,
    pageNumber: 1,
    pageSize: 10,
    error: false,
    errorMessage: undefined,
    locations: undefined,
    archivedloading: false,
    archivedTotalCount: undefined,
    archivedPageNumber: 1,
    archivedError: false,
    archivedErrorMessage: undefined,
    archivedLocations: undefined,
    archivedSearchTerm: undefined,
    archivedSort: undefined,
    showBulkModal: false,
    selectedFilters: [],
    locationTabMode: 'active',
    locationScope: undefined,
    organizationId: null,
  },
  reducers: {
    locationsLoading(state) {
      state.loading = true;
    },
    isShowBulkModal(state, action) {
      const { showBulkModal } = action.payload;
      state.showBulkModal = showBulkModal;
    },
    setLocationFilters(state, action) {
      const { selectedFilters } = action.payload;
      state.selectedFilters = selectedFilters;
    },
    setLocationScope(state, action) {
      const { locationScope } = action.payload;
      state.locationScope = locationScope;
    },
    setLocationOrganization(state, action) {
      const { orgId } = action.payload;
      state.organizationId = orgId;
    },
    setLocationTabMode(state, action) {
      const { mode } = action.payload;
      state.locationTabMode = mode;
    },
    locationsError(state, action) {
      state.error = true;
      state.errorMessage = action.payload;
    },
    locationsReceived(state, action) {
      const {
        page,
        pageNumber,
        pageSize,
        totalCount,
        sort,
        searchTerm,
      } = action.payload;

      state.loading = false;
      state.locations = page;
      state.pageSize = pageSize;
      state.pageNumber = pageNumber;
      state.totalCount = totalCount;
      state.searchTerm = searchTerm;
      state.sort = sort;
    },
    resetLocations(state) {
      state.loading = false;
      state.locations = undefined;
      state.pageSize = 10;
      state.pageNumber = 1;
      state.totalCount = undefined;
      state.searchTerm = undefined;
      state.sort = undefined;
    },
    updateLocations(state, action) {
      const { page } = action.payload;

      state.loading = false;
      state.locations = page;
    },
    archivedLocationsLoading(state) {
      state.archivedLoading = true;
    },
    archivedLocationsError(state, action) {
      state.archivedError = true;
      state.archivedErrorMessage = action.payload;
    },
    archivedLocationsReceived(state, action) {
      const {
        page,
        pageNumber,
        totalCount,
        searchTerm,
        sort,
        locationTabMode,
      } = action.payload;

      state.archivedLoading = false;
      state.archivedLocations = page;
      state.archivedPageNumber = pageNumber;
      state.archivedTotalCount = totalCount;
      state.archivedSearchTerm = searchTerm;
      state.archivedSort = sort;
      state.locationTabMode = locationTabMode;
    },
    searchTermChanged(state, action) {
      state.searchTerm = action.payload;
    },
    locationEdited(state, action) {},
    locationCreated(state, action) {},
    invalidateLocations(state, action) {
      state.locations = undefined;
      state.archivedLocations = undefined;
    },
    setStatesAndCountries(state, action) {
      const { states, countries } = action.payload;
      state.states = states;
      state.countries = countries;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('organizations/organizationNameChanged', (state, action) => {
        const { code, name } = action.payload;

        const locations = state.locations || [];
        const archivedLocations = state.archivedLocations || [];
        const allLocations = [...locations, ...archivedLocations];

        for (const location of allLocations) {
          const shouldRenameOrganization =
            location.organization && location.organization.oldId === code;
          if (shouldRenameOrganization) {
            location.organization.name = name;
          }
        }
      })
      .addCase('machines/machinesStateChanged', (state, action) => {
        state.locations = undefined;
        state.archivedLocations = undefined;
      });
  },
});

export const machineShippedOrOrphaned = () => (dispatch, getState) => {
  dispatch(invalidateLocations());
};

export const locationCreated = (location) => (dispatch, getState) => {
  dispatch(fetchLocations());
  dispatch(locationsSlice.actions.locationCreated(location));
};

export const locationEdited = (location) => (dispatch, getState) => {
  const state = getState().locations;

  const findLocation = (loc) => loc.id === location.id;
  const foundLocation =
    (state.locations && state.locations.find(findLocation)) ||
    (state.archivedLocations && state.archivedLocations.find(findLocation));

  if (!foundLocation) {
    return;
  }

  dispatch(locationsSlice.actions.locationEdited(location));
  dispatch(fetchLocations());
};

export const fetchLocations = ({
  pageSize,
  pageNumber,
  searchTerm,
  sort,
  organizationId,
  filters,
  scope,
  mode,
} = {}) => async (dispatch, getState) => {
  try {
    const locationsState = getState().locations;
    const organizationsState = getState().organizations;

    mode = mode || locationsState.locationTabMode;
    pageSize = pageSize || locationsState.pageSize;
    pageNumber = pageNumber || locationsState.pageNumber;
    searchTerm =
      searchTerm !== undefined ? searchTerm : locationsState.searchTerm;
    sort = sort || locationsState.sort;
    filters = filters || locationsState.selectedFilters;
    organizationId =
      organizationsState.currentOrganizationId ||
      scope ||
      locationsState.locationScope;

    if (mode === 'archive') {
      dispatch(archivedLocationsLoading());
    } else {
      dispatch(locationsLoading());
    }

    let result = await axios.post(
      '/api/locations/getPage',
      {
        pageNumber,
        pageSize,
        organizationId,
        statuses: mode === 'archive' ? ['archive'] : ['active', 'inactive'],
        searchTerm,
        sort,
        filters,
      },
      getAuthHeaders()
    );

    const locations = result.data.data;
    if (mode === 'archive') {
      dispatch(
        archivedLocationsReceived({
          ...locations,
          searchTerm,
          sort,
          locationTabMode: mode,
        })
      );
    } else if (mode === 'active') {
      dispatch(
        locationsReceived({
          ...locations,
          searchTerm,
          sort,
          locationTabMode: mode,
        })
      );
    }
  } catch (error) {
    dispatch(locationsError(error.message));
  }
};

export const updateLocationTabMode = (mode) => async (dispatch, getState) => {
  dispatch(setLocationTabMode(mode));
};

export const fetchLocationsByFilter = ({
  searchTerm,
  filters,
  organizationId,
  scope,
} = {}) => async (dispatch, getState) => {
  try {
    const locationsState = getState().locations;
    const organizationsState = getState().organizations;
    let mode = locationsState.locationTabMode;
    searchTerm =
      searchTerm !== undefined ? searchTerm : locationsState.searchTerm;
    organizationId =
      organizationsState.currentOrganizationId ||
      scope ||
      locationsState.locationScope;
    const result = await axios.post(
      '/api/locations/getListByFilter',
      {
        organizationId,
        statuses: mode === 'archive' ? ['archive'] : ['active', 'inactive'],
        searchTerm,
        filters,
      },
      getAuthHeaders()
    );
    return result.data.data;
  } catch (error) {
    dispatch(locationsError(error.message));
  }
};

export const setShowBulkModal = (modal) => async (dispatch, getState) => {
  dispatch(isShowBulkModal(modal));
};

export const updateLocationFilters = (filters) => async (
  dispatch,
  getState
) => {
  dispatch(setLocationFilters({ selectedFilters: filters }));
  if (!filters.length) {
    dispatch(fetchLocations());
  }
};

export const updateLocationsScope = (locationScope) => async (dispatch) => {
  dispatch(setLocationScope({ locationScope }));
  dispatch(fetchLocations());
};

export const bulkUpdateLocations = ({
  isAllLocations,
  locations,
  organizationId,
  toUpdate,
  scope,
} = {}) => async (dispatch, getState) => {
  try {
    const locationsState = getState().locations;
    const organizationsState = getState().organizations;
    const {
      searchTerm,
      sort,
      selectedFilters,
      locationTabMode: mode,
      locationScope,
    } = locationsState;
    scope = organizationsState.currentOrganizationId || scope || locationScope;
    const result = await axios
      .put(
        '/api/locations/multi',
        {
          isAllLocations,
          locations,
          toUpdate,
          statuses: mode === 'archive' ? ['archive'] : ['active', 'inactive'],
          searchTerm,
          organizationId: organizationsState.currentOrganizationId,
          filters: selectedFilters,
        },
        getAuthHeaders()
      )
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });

    if (!result.ok) {
      throw new Error('Bulk Operation Failure');
    }
    dispatch(
      fetchLocations({
        searchTerm,
        pageNumber: 1,
        sort,
        filters: selectedFilters,
        mode,
      })
    );

    return true;
  } catch (error) {
    throw error;
  }
};

export const {
  locationsLoading,
  locationsError,
  locationsReceived,
  resetLocations,
  updateLocations,
  isShowBulkModal,
  setLocationFilters,
  setLocationScope,
  setLocationOrganization,
  setLocationTabMode,
  archivedLocationsLoading,
  archivedLocationsError,
  archivedLocationsReceived,
  organizationNameChanged,
  invalidateLocations,
  setStatesAndCountries,
} = locationsSlice.actions;
export const { reducer: locationsReducer } = locationsSlice;
export default locationsReducer;
