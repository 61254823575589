// @flow
import * as React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { flowRight as compose } from 'lodash';
import MasterLayout from '../lib/layout/MasterLayout';
import LoginPage from '../pages/auth/LoginPage';
import LoaderPage from '../lib/layout/LoaderPage';
import { auth } from '../Auth';
import * as operations from '../operations';
import { Translate } from '../base/Translate';

import './NoScopePage.css';

class NoScopePage extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isLoggedIn !== nextProps.isLoggedIn) {
      this.props.data.refetch();
    }
  }

  componentDidMount() {
    const wasAuthenticated = auth.isAuthenticated();
    this.unlistenToAuthChanges = auth.addOnAuthChangedListener(
      (isAuthenticated) => {
        if (wasAuthenticated && !isAuthenticated) {
          operations.logout();
        }
      }
    );
  }

  componentWillUnmount() {
    this.unlistenToAuthChanges();
  }

  goToStore() {
    window.location.href = 'https://store.drinkripples.com';
  }

  render() {
    if (!this.props.isLoggedIn) {
      return <LoginPage />;
    }
    if (this.props.data.loading) {
      return <LoaderPage />;
    }
    if (
      !this.props.isLoggedIn ||
      !this.props.data ||
      !this.props.data.viewer ||
      !this.props.data.viewer.isLoggedIn
    ) {
      return <LoginPage {...this.props} />;
    }

    let currentUser = this.props.data.viewer.me.name;
    const { logout } = this.props || {};
    return (
      <MasterLayout
        userName={currentUser}
        onLogout={logout}
        onHomeClicked={() => {}}
        hideSidebar={true}
      >
        <div className="rpl-NoScopePage">
          <div className="NoScopePage__inner">
            <div className="rpl-NoScopePage__image" />
            <div className="rpl-NoScopePage__content">
              <h2>
                <Translate transkey="4c193514-523a-4dad-832f-199a12088060">
                  Welcome to Ripples Cloud
                </Translate>
                <br />
                <Translate transkey="f585b6c7-03bc-462f-a277-e64af0096181">
                  Let the Magic Begin!
                </Translate>
              </h2>
              <p className="rpl-NoScopePage__red">
                <Translate transkey="c40327e2-9b82-4e7f-a90f-75213a53243f">
                  Your user is not assigned to any organization
                </Translate>
              </p>
              <p>
                <Translate transkey="2c9eb4ef-3f07-4bee-ac77-242eff5b2777">
                  Contact your organization's admin and ask to be added to your
                  organization
                </Translate>
              </p>
              <p className="rpl-NoScopePage__or">
                <Translate transkey="c99b8bf8-a46d-450e-b28f-6589766dc211">
                  Or
                </Translate>
              </p>
              <p>
                <Translate transkey="88fa31e5-db59-409e-9cf6-357d8bf537fc">
                  If you don't have a Ripple Maker, Go to the store
                </Translate>
              </p>
              <p className="rpl-NoScopePage__button">
                <button onClick={this.goToStore}>
                  <Translate transkey="5f4354fe-fc22-4265-bdde-36255f178b0f">
                    Shop Now
                  </Translate>
                </button>
              </p>
            </div>
          </div>
        </div>
      </MasterLayout>
    );
  }
}

const LoadUserQuery = gql`
  query {
    viewer {
      isLoggedIn
      me {
        id
        name
        email
        roles
        permissions
        orgId
        databaseDetails {
          timeout
        }
      }
    }
  }
`;

export default compose(
  graphql(LoadUserQuery, {
    props: ({ ownProps, data }) => {
      let permissions;
      let mainOrganization;
      if (data && data.viewer) {
        permissions = data.viewer.me.permissions;
        mainOrganization = data.viewer.me.orgId;

        // set inactivity timeout based on settings fro specific user
        const timeoutMs = data.viewer.me.databaseDetails.timeout * 1000;
        auth.updateInactivityTimeout(timeoutMs);
      }
      return {
        mainOrganization,
        permissions,
        data,
      };
    },
  }),
  require('../hoc/withIdentity').withLogoutAction(),
  require('../hoc/withIdentity').withLoginState()
)(NoScopePage);
