import { createSlice } from '@reduxjs/toolkit';
import type { Machine } from '../maker-types';
import axios from 'axios';
import { getAuthHeaders } from '../../../../Auth';
import { machineShippedOrOrphaned } from '../../locations/store/locationsSlice';

const machinesSlice = createSlice({
  name: 'machines',
  initialState: {
    loading: false,
    total: 0,
    machines: undefined,
    page: 1,
    pageSize: 10,
    filters: [],
    searchTerm: '',
    searchFilters: {},
    sortField: undefined,
    sortOrder: undefined,
  },
  reducers: {
    machinesLoading(state) {
      state.loading = true;
    },
    machinesReceived(state, action) {
      const { machines, total } = action.payload;

      state.loading = false;
      state.machines = machines;
      state.total = total;
    },
    machineSearchChanged(state, action) {
      const {
        page,
        filters,
        pageSize,
        searchTerm,
        sortField,
        sortOrder,
      } = action.payload;

      state.page = page;
      state.pageSize = pageSize;
      state.filters = filters;
      state.searchTerm = searchTerm;
      state.sortField = sortField;
      state.sortOrder = sortOrder;
    },
    machineUpdated(state, action) {
      const updatedMachine = action.payload;

      if (!state.machines) {
        return;
      }

      state.machines = state.machines.map(
        (machine) =>
          machine.serial === updatedMachine.serial ? updatedMachine : machine
      );
    },
    machinesDoneLoading(state) {
      state.loading = false;
    },
    machinesAppsVersionUpdated(state, action) {
      const { appsVersion, serials } = action.payload;

      for (const machine of state.machines) {
        if (serials.indexOf(machine.serial) !== -1) {
          machine.versions.appsVersion = appsVersion;
        }
      }
    },
    machinesRm2VersionUpdated(state, action) {
      const { rm2Version, serials } = action.payload;

      for (const machine of state.machines) {
        if (serials.indexOf(machine.serial) !== -1) {
          machine.versions.rm2Version = rm2Version;
        }
      }
    },
    machinesStateChanged() {},
    searchTermChanged(state, action) {
      const searchTerm = action.payload;

      const searchFilters = searchTerm.trim()
        ? [
            {
              field: 'serial',
              operator: 'Contains',
              value: searchTerm.trim().toLowerCase(),
            },
            {
              field: 'accountData.locationName',
              operator: 'Contains',
              value: searchTerm.trim().toLowerCase(),
            },
            {
              field: 'accountData.accountName',
              operator: 'Contains',
              value: searchTerm.trim().toLowerCase(),
            },
          ]
        : [];

      if (
        searchTerm.toLowerCase().includes('rm1') ||
        searchTerm.toLowerCase().includes('rm2')
      ) {
        const typeFilter = {
          field: 'type',
          operator: searchTerm.toLowerCase().includes('rm2')
            ? `=`
            : `Doesn't Contain`,
          value: 2,
        };
        searchFilters.push(typeFilter);
      }

      state.searchFilters = searchFilters;

      state.searchTerm = searchTerm;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('organizations/organizationNameChanged', (state, action) => {
        const { code, name } = action.payload;

        if (state.machines) {
          for (const machine of state.machines) {
            if (
              machine &&
              machine.accountData &&
              machine.accountData.accountCode === code
            ) {
              machine.accountData.accountName = name;
            }
          }
        }
      })
      .addCase('locations/locationEdited', (state, action) => {
        const { name, id, country } = action.payload;

        if (state.machines) {
          for (const machine of state.machines) {
            if (machine && machine.location === id) {
              machine.accountData.locationName = name;
              machine.accountData.country = country;
            }
          }
        }
      });
  },
});

export const fetchMachines = (
  props
): Promise<{ machines: Machine[], total: number }> => async (
  dispatch,
  getState
) => {
  const machinesState = getState().makers.machines;
  const { currentOrganizationId } = getState().organizations;
  props = { ...machinesState, ...props };
  props.pageSize = props.pageSize || machinesState.pageSize;
  let {
    page,
    filters,
    searchFilters,
    searchTerm,
    sortField,
    sortOrder,
    orgId,
    pageSize,
  } = props;

  const sort = sortField ? { [sortField]: sortOrder } : { lastPing: -1 };

  dispatch(machineSearchChanged(props));
  dispatch(machinesLoading());

  const res = await axios.post(
    '/api/makers/machines',
    {
      pageNumber: page,
      pageSize,
      sort,
      filters,
      searchFilters: searchTerm ? searchFilters : [],
      orgId: orgId || currentOrganizationId,
    },
    getAuthHeaders()
  );

  const result: any = res.data.data;
  const machines: Machine[] = result.machines;
  const total = result.totalCount;

  dispatch(
    machinesReceived({
      machines,
      total,
    })
  );
};

export const machineStateChanged = (serial) => async (dispatch, getState) => {
  const state = getState();
  const { machines } = state.makers.machines;

  dispatch(machineShippedOrOrphaned());
  if (!machines) {
    return;
  }

  const isMachineInList = machines.some((machine) => machine.serial === serial);

  if (isMachineInList) {
    dispatch(machinesLoading());
    const res = await axios.get(
      `/api/makers/machines/${serial}`,
      getAuthHeaders()
    );
    const updatedMachine = res.data.data;
    dispatch(machineUpdated(updatedMachine));
    dispatch(machinesDoneLoading());
  }
};

export const machinesBulkOperationDone = (fields, serials) => async (
  dispatch,
  getState
) => {
  if (fields.state) {
    dispatch(machinesStateChanged());
    dispatch(fetchMachines());
    return;
  }

  if (fields.versions && fields.versions.appsVersion) {
    dispatch(
      machinesAppsVersionUpdated({
        appsVersion: fields.versions.appsVersion,
        serials,
      })
    );
  }
  if (fields.versions && fields.versions.rm2Version) {
    dispatch(
      machinesRm2VersionUpdated({
        rm2Version: fields.versions.rm2Version,
        serials,
      })
    );
  }
};

export const {
  machinesLoading,
  machinesReceived,
  machineSearchChanged,
  machineUpdated,
  machinesDoneLoading,
  machinesStateChanged,
  machinesAppsVersionUpdated,
  machinesRm2VersionUpdated,
  searchTermChanged,
} = machinesSlice.actions;
export const { reducer: machinesReducer } = machinesSlice;
export default machinesReducer;
