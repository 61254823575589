import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Layout, Button, Popover, Spin } from 'antd';
import Sidebar from './Sidebar';
import classnames from 'classnames';
import { withBreak } from '../breaks';
import { PageTitleProvider } from './PageTitle';
import SidebarIdentity from './SidebarIdentity';
import ScopePicker from './ScopePicker';
import MenuIcon from '../img/MenuIcon';
import { IntlProvider } from 'react-intl';
import { Translate, t } from '../../base/Translate';
import newLogo from './../img/RipplesLogoNew.png';
import { LoadingOutlined } from '@ant-design/icons';
import './MasterLayout.css';

const MasterLayout = (props) => {
  const [showVersion, setShowVersion] = useState(false);
  const [drawerActive, setDrawerActive] = useState(props.drawerActive);
  const [drawerPinned, setDrawerPinned] = useState(false);
  const [sidebarPinned, setSidebarPinned] = useState(false);
  const [title, setTitle] = useState('');
  const [user, setUser] = useState('');

  useEffect(
    () => {
      if (typeof props.context === 'undefined') {
        return setUser(
          <Spin
            indicator={<LoadingOutlined spin />}
            className="text-white"
            size="small"
          />
        );
      }
      setUser(props.context);
    },
    [props.context]
  );

  useEffect(
    () => {
      setDrawerActive(props.drawerActive);
    },
    [props.drawerActive]
  );

  useEffect(
    () => {
      if (showVersion) {
        const hideVersionTimeout = setTimeout(
          () => setShowVersion(false),
          3000
        );
        return () => clearTimeout(hideVersionTimeout);
      }
    },
    [showVersion]
  );

  const toggleDrawerActive = useCallback(
    () => {
      props.onToggleDrawer();
    },
    [props]
  );

  const toggleVersion = useCallback(() => {
    setShowVersion((prevShowVersion) => !prevShowVersion);
  }, []);

  const onHomeClicked = useCallback(
    () => {
      props.onHomeClicked();
    },
    [props]
  );

  const handleStoreClick = useCallback(() => {
    const storeUrl = 'https://store.drinkripples.com';
    window.open(storeUrl);
  }, []);

  let sidebarContent = null;
  const content = [];

  React.Children.forEach(props.children, (c, idx) => {
    if (!c) return;
    if (c.type === SidebarIdentity) {
      // Skip SidebarIdentity, or handle it if necessary
    } else if (c.type === Sidebar) {
      sidebarContent = c;
    } else {
      content.push(React.cloneElement(c, { key: idx }));
    }
  });

  const screenSizeRootClassName = {
    'rpl-Break__desktop': props.isDesktop,
    'rpl-Break__tablet': props.isTablet,
    'rpl-Break__mobile': !props.isTablet && !props.isDesktop,
  };

  let hideSidebar = !props.isDesktop ? !drawerActive : false;

  if (props.hideSidebar) {
    hideSidebar = true;
  }

  return (
    <IntlProvider locale="en">
      <Layout id="rpl-layout">
        {drawerActive &&
          !props.isDesktop && (
            <div className="rpl-layout__dark" onClick={toggleDrawerActive} />
          )}
        <Layout.Sider
          className={classnames(
            'rpl-MasterLayout__drawer',
            screenSizeRootClassName
          )}
          id="rpl-sidebar"
          collapsed={hideSidebar}
          width={256}
          collapsedWidth={0}
          collapsible
          trigger={null}
          style={
            drawerActive && !props.isDesktop
              ? {
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 1000,
                }
              : null
          }
        >
          <div className="rpl-MasterLayout__brand">
            <div
              className="rpl-MasterLayout__brand-logo"
              onClick={onHomeClicked}
            >
              <img src={newLogo} alt="" style={{ width: '150px' }} />
            </div>
          </div>

          <div className="rpl-MasterLayout__sidebar-header">
            <div
              className="rpl-MasterLayout__identity"
              onClick={props.onToggleScopesClick}
            >
              <div
                className={classnames('rpl-MasterLayout__identity-context', {
                  expanded: props.isScopesOpen,
                })}
              >
                <div className="rpl-MasterLayout__identity-context-name">
                  <span>{user}</span>
                </div>
                <div className="rpl-MasterLayout__identity-context-chevron" />
              </div>
            </div>
          </div>

          <div className="rpl-MasterLayout__sidebar">
            <div
              className={classnames('rpl-MasterLayout__scopes', {
                visible: props.isScopesOpen,
              })}
            >
              <ScopePicker
                onToggleScopesClick={props.onToggleScopesClick}
                mainOrganization={props.mainOrganization}
                setCurrentOrganization={props.setCurrentOrganization}
              />
            </div>
            <div
              className={classnames('rpl-MasterLayout__sidebar-shadow', {
                visible: !props.isSidebarActive,
              })}
            />
            {sidebarContent}
          </div>
        </Layout.Sider>

        <Layout.Content
          className={classnames(
            'rpl-MasterLayout__panel',
            screenSizeRootClassName
          )}
          id="rpl-content"
        >
          <Layout.Header id="rpl-header">
            {!props.isDesktop && (
              <>
                <div
                  className="rpl-MasterLayout__toggle-sidebar"
                  onClick={toggleDrawerActive}
                >
                  <MenuIcon />
                </div>
                <h1 className="rpl-MasterLayout__title">{title}</h1>
              </>
            )}

            <div
              className="rpl-MasterLayout__version-bar"
              onClick={toggleVersion}
            >
              <span
                className={classnames('rpl-MasterLayout__version-bar-content', {
                  visible: showVersion && props.version,
                })}
              >
                {props.version}
              </span>
            </div>

            <Popover
              align={{ offset: [0, 5] }}
              content={
                <div>
                  <Button id={'rpl-logout-button'} onClick={props.onLogout}>
                    <Translate>Logout</Translate>
                  </Button>
                </div>
              }
              trigger="click"
            >
              <div className="rpl-MasterLayout__identity-name">
                {props.userName}
              </div>
            </Popover>

            <button
              className="rpl-MasterLayout__store-button"
              onClick={handleStoreClick}
            >
              {t(['66a811a2-21ec-4470-8755-a04111c1cfd4', 'View In Store'])}
            </button>
          </Layout.Header>

          <PageTitleProvider onChange={setTitle}>
            <div className="rpl-MasterLayout__panel-content">{content}</div>
          </PageTitleProvider>
        </Layout.Content>
      </Layout>
    </IntlProvider>
  );
};

MasterLayout.propTypes = {
  onLogout: PropTypes.func,
  isDesktop: PropTypes.bool,
  isTablet: PropTypes.bool,
  title: PropTypes.string,
  version: PropTypes.string,
  userName: PropTypes.string,
  context: PropTypes.string,
  isScopesOpen: PropTypes.bool,
  onToggleScopesClick: PropTypes.func,
  onToggleDrawer: PropTypes.func,
  drawerActive: PropTypes.bool,
  onHomeClicked: PropTypes.func,
  isSidebarActive: PropTypes.bool,
  setCurrentOrganization: PropTypes.func,
  hideSidebar: PropTypes.bool,
};

export default withBreak()(MasterLayout);
