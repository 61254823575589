import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getAuthHeaders } from '../../../../Auth';
import { t } from '../../../../base/Translate';

const hierarchySlice = createSlice({
  name: 'hierarchy',
  initialState: {
    treeData: [],
    loading: true,
    error: false,
    search: '',
    openNodes: [],
    errorMessage: undefined,
  },
  reducers: {
    hierarchyLoading(state) {
      state.loading = true;
    },
    hierarchyError(state, action) {
      state.error = true;
      state.errorMessage = action.payload;
    },
    hierarchyReceived(state, action) {
      state.treeData = action.payload;
      state.loading = false;
    },
    hierarchySearch(state, action) {
      state.search = action.payload;
    },
    hierarchyOpenNodes(state, action) {
      state.openNodes = action.payload;
    },
  },
});

const translateTreeData = (treeData) => {
  treeData.forEach((node) => {
    if (node.titleTranslationKey) {
      node.title = t([node.titleTranslationKey, node.title]);
    }

    if (node.subtitleTranslationKey) {
      node.subtitle = t([node.subtitleTranslationKey, node.subtitle]);
    }

    node.children = translateTreeData(node.children);
  });
  return treeData;
};

export const fetchHierarchy = (props: any) => async (dispatch, getState) => {
  dispatch(hierarchyLoading());
  const { currentOrganizationId } = getState().organizations;
  const { openNodes } = getState().hierarchy;
  const body = {
    scopeOrgId: currentOrganizationId,
    openNodes: openNodes || [],
  };
  const searchedTree = await axios.post(
    `/api/hierarchy/hierarchySearch`,
    body,
    getAuthHeaders()
  );
  const treeData = translateTreeData([searchedTree.data.data]);
  dispatch(hierarchyReceived(treeData));
};

export const setHierarchySearchTerm = (search: string) => async (
  dispatch,
  getState
) => {
  dispatch(hierarchySearch(search));
};

export const setOpenNodes = (id: string | string[]) => async (
  dispatch,
  getState
) => {
  let openNodes = getState().hierarchy.openNodes.map((id) => id);
  if (Array.isArray(id)) {
    openNodes = [...openNodes, ...id];
  } else if (openNodes.includes(id)) {
    openNodes.splice(openNodes.indexOf(id), 1);
  } else {
    openNodes.push(id);
  }
  openNodes.filter((n) => typeof n === 'string');
  dispatch(hierarchyOpenNodes(openNodes));
};

export const {
  hierarchyLoading,
  hierarchyError,
  hierarchyReceived,
  hierarchySearch,
  hierarchyOpenNodes,
} = hierarchySlice.actions;

export const { reducer: hierarchyReducer } = hierarchySlice;
export default hierarchyReducer;
