import axios from 'axios';
import { getAuthHeaders } from '../Auth';

export const getOrganizationByOrgId = async (organizationId: string) => {
  try {
    const organizationResult = await axios.get(
      `/api/organizations/byOrgId/${organizationId}`,
      getAuthHeaders()
    );
    const organization = organizationResult.data.data;

    return organization;
  } catch (err) {
    console.error(err);
  }
};
