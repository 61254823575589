// @flow strict

const constructConfig = () => {
  const config = {
    connection: 'Username-Password-Authentication',
    redirectUri: window.location.origin + '/loginCallback',
    responseType: 'token id_token',
    scope: 'openid',
    ripplesKey: 'f309acb6-ed7f-484c-8f85-da6fc0154574',
  };

  const env = process.env.REACT_APP_ENV;
  if (env) {
    console.info(`***** Using Localhost ${env} *****`);
    switch (env) {
      case 'production': {
        return {
          ...config,
          domain: 'ripples.auth0.com',
          clientID: '0cpffHOAEtZr3U3QmMrlfUytoVjDa2Qg',
          audience: 'https://my.drinkripples.com',
          returnTo: 'https://www.drinkripples.com',
          ga4MeasurementId: 'G-RLY47NRQJH',
        };
      }

      case 'staging': {
        return {
          ...config,
          domain: 'ripples-staging.auth0.com',
          clientID: 'jz4F6vBWV0t4re7QqUU327P6xbttBUtX',
          audience: 'https://my-st.drinkripples.com',
          returnTo:
            'https://rplportalstatic1.blob.core.windows.net/pages/landing.html',
          ga4MeasurementId: 'G-BJWN72KS86',
        };
      }

      case 'development':
      default: {
        return {
          ...config,
          domain: 'ripples-development.auth0.com',
          clientID: 'vBxADQtGsQzWBL1xty51uPct8vjW9AVI',
          audience: 'https://my-dev.drinkripples.com',
          returnTo:
            'https://rplportalstatic0.blob.core.windows.net/pages/landing.html',
          ga4MeasurementId: 'G-XR99BPJRE3',
        };
      }
    }
  } else {
    // Original implementation based on window.location.host
    switch (window.location.host) {
      case 'my.drinkripples.com':
      case 'my.service.drinkripples.com': {
        return {
          ...config,
          domain: 'ripples.auth0.com',
          clientID: '0cpffHOAEtZr3U3QmMrlfUytoVjDa2Qg',
          audience: 'https://my.drinkripples.com',
          returnTo: 'https://www.drinkripples.com',
          ga4MeasurementId: 'G-RLY47NRQJH',
        };
      }

      case 'my.stg.coffeeripples.com': {
        return {
          ...config,
          domain: 'ripples-staging.auth0.com',
          clientID: 'jz4F6vBWV0t4re7QqUU327P6xbttBUtX',
          audience: 'https://my-st.drinkripples.com',
          returnTo:
            'https://rplportalstatic1.blob.core.windows.net/pages/landing.html',
          ga4MeasurementId: 'G-BJWN72KS86',
        };
      }

      default: {
        return {
          ...config,
          domain: 'ripples-development.auth0.com',
          clientID: 'vBxADQtGsQzWBL1xty51uPct8vjW9AVI',
          audience: 'https://my-dev.drinkripples.com',
          returnTo:
            'https://rplportalstatic0.blob.core.windows.net/pages/landing.html',
          ga4MeasurementId: 'G-XR99BPJRE3',
        };
      }
    }
  }
};

const config = constructConfig();

export default config;
