import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { gql } from '@apollo/client';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux';
import axios from 'axios';
import withNavigate from './withNavigate';
import { getAuthHeaders } from '../Auth';
import {
  organizationChanged,
  organizationCreated,
} from '../pages/entities/organizations/store/organizationsSlice';
import {
  locationCreated,
  locationEdited,
} from '../pages/entities/locations/store/locationsSlice';

import { getScopeRoot } from '../locations';
import locations from '../locations';
import { operationSuccessful, operationWithError } from '../notifications';
import { get } from 'lodash';
import { t, tHTML } from '../base/Translate';

export const Query = gql`
  query baseHoc($orgId: String = "", $withActiveServiceLevel: Boolean = false) {
    viewer @include(if: $withActiveServiceLevel) {
      me {
        id
        name
      }
      countries {
        id
        name
      }
      states {
        id
        name
        state
      }
      organizationScope(id: $orgId) {
        organization(id: $orgId) {
          name
          features {
            isPartner
            isSkinDesignerEnabled
          }
          isRipplesOrgId
          activeServiceLevel
        }
      }
    }
  }
`;

const editOrganizationMutation = gql`
  mutation editOrganization(
    $orgId: String!
    $name: String!
    $googleAnalyticsId: String!
    $features: OrgFeatures!
    $supportDetails: OrgSupportDetails!
    $parentId: String
    $inheritContent: Boolean!
    $screenSaver: String
    $shareContent: Boolean!
    $isPartnerChanged: Boolean!
  ) {
    editOrganization(
      input: {
        clientMutationId: "1"
        orgId: $orgId
        name: $name
        googleAnalyticsId: $googleAnalyticsId
        features: $features
        supportDetails: $supportDetails
        parentId: $parentId
        inheritContent: $inheritContent
        screenSaver: $screenSaver
        shareContent: $shareContent
        isPartnerChanged: $isPartnerChanged
      }
    ) {
      clientMutationId
    }
  }
`;

const createOrganizationMutation = gql`
  mutation createOrganization(
    $name: String!
    $googleAnalyticsId: String!
    $features: OrgFeatures!
    $supportDetails: OrgSupportDetails!
    $parentId: String!
    $inheritContent: Boolean!
    $screenSaver: String
    $shareContent: Boolean!
    $highSecurityUsers: Boolean!
  ) {
    createOrganization(
      input: {
        clientMutationId: "1"
        name: $name
        googleAnalyticsId: $googleAnalyticsId
        features: $features
        supportDetails: $supportDetails
        parentId: $parentId
        inheritContent: $inheritContent
        screenSaver: $screenSaver
        shareContent: $shareContent
        highSecurityUsers: $highSecurityUsers
      }
    ) {
      clientMutationId
      organization {
        id
      }
    }
  }
`;

const editRippleMakerLocationMutation = gql`
  mutation editRippleMakerLocation(
    $id: String!
    $name: String!
    $city: String!
    $state: String
    $notes: String
    $zipCode: String
    $logoUrl: String
    $address: String!
    $country: String!
    $latitude: Float!
    $longitude: Float!
    $isPrivate: Boolean
    $isForEvents: Boolean
    $contactEmail: String
    $organization: String!
    $displayedName: String
    $contactLastName: String
    $displayedAddress: String
    $contactFirstName: String
    $isWebappEnabled: Boolean
    $contactPhoneNumber: String
  ) {
    editRippleMakerLocation(
      input: {
        id: $id
        name: $name
        city: $city
        state: $state
        notes: $notes
        address: $address
        zipCode: $zipCode
        logoUrl: $logoUrl
        country: $country
        latitude: $latitude
        clientMutationId: "1"
        isPrivate: $isPrivate
        longitude: $longitude
        isForEvents: $isForEvents
        contactEmail: $contactEmail
        organization: $organization
        displayedName: $displayedName
        contactLastName: $contactLastName
        isWebappEnabled: $isWebappEnabled
        displayedAddress: $displayedAddress
        contactFirstName: $contactFirstName
        contactPhoneNumber: $contactPhoneNumber
      }
    ) {
      clientMutationId
    }
  }
`;

const createRippleMakerLocationMutation = gql`
  mutation createRippleMakerLocation(
    $name: String!
    $city: String!
    $state: String
    $notes: String
    $logoUrl: String
    $zipCode: String
    $address: String!
    $country: String!
    $latitude: Float!
    $longitude: Float!
    $isPrivate: Boolean
    $isForEvents: Boolean
    $contactEmail: String
    $organization: String!
    $displayedName: String
    $contactLastName: String
    $displayedAddress: String
    $contactFirstName: String
    $isWebappEnabled: Boolean
    $contactPhoneNumber: String
  ) {
    createRippleMakerLocation(
      input: {
        clientMutationId: "1"
        name: $name
        city: $city
        state: $state
        notes: $notes
        zipCode: $zipCode
        country: $country
        logoUrl: $logoUrl
        address: $address
        latitude: $latitude
        isPrivate: $isPrivate
        longitude: $longitude
        isForEvents: $isForEvents
        organization: $organization
        contactEmail: $contactEmail
        displayedName: $displayedName
        contactLastName: $contactLastName
        isWebappEnabled: $isWebappEnabled
        displayedAddress: $displayedAddress
        contactFirstName: $contactFirstName
        contactPhoneNumber: $contactPhoneNumber
      }
    ) {
      clientMutationId
    }
  }
`;

const LoadScopesQuery = `
query {
  viewer {
    me {
      id
    }
  }
}
`;

const onEditOrganization = (props, organization, dispatch) => {
  const { name, googleAnalyticsId, features, supportDetails } = organization;
  return props
    .editOrganization(organization)
    .then(() => {
      operationSuccessful({
        msg: t([
          '19ae1f46-336a-47e7-b828-659892893e4e',
          'An Organization was edited',
        ]),
      });
      dispatch(
        organizationChanged({
          name,
          googleAnalyticsId,
          features,
          supportDetails,
        })
      );
    })
    .catch((error) => {
      operationWithError({
        msg: t([
          'dd0ec4e7-3f55-4506-9f7a-89b6952d4ae2',
          'There was an error while editing an organization',
        ]),
        error,
      });
    });
};

const onCreateOrganization = (props, organization, dispatch) => {
  const SuccessMessage = ({ orgId }) => {
    const usersLink = `/org/${orgId}/users`;

    const CreateUser = () => (
      <a href={usersLink} className="rpl-EditOrganizationModal__link">
        {t(['a25ed0c0-8ea2-4f69-a5b3-b0f26743c80f', 'Create user'])}
      </a>
    );

    return (
      <p>
        {t([
          '64b746a3-aa8f-4753-b4cb-2b8b2640564b',
          'An organization was created.',
        ])}
        <br />
        {tHTML(
          [
            'f9534bfa-91de-418f-b8c9-a94299cdad3f',
            'Go to Organization to {Create_user}',
          ],
          {
            Create_user: <CreateUser key={orgId} />,
          }
        )}
      </p>
    );
  };

  return props
    .createOrganization(organization)
    .then(({ data }) => {
      const orgId = get(data, 'createOrganization.organization.id', false);
      if (orgId) {
        operationSuccessful({
          msg: <SuccessMessage orgId={orgId} />,
        });
      }
      dispatch(organizationCreated());
    })
    .catch((error) => {
      operationWithError({
        msg: t([
          '02f97c8b-847f-4122-b5a2-54b45453d4b5',
          'There was an error while creating an organization',
        ]),
        error,
      });
    });
};

const onEditLocation = async (props, params, dispatch) => {
  const { countryName } = params;
  return await props
    .editRippleMakerLocation(params)
    .then(() => {
      dispatch(locationEdited({ ...params, country: countryName }));
      operationSuccessful({
        msg: t([
          'd3454bd2-ac4d-4b78-8950-5aba2efcc1cc',
          'A location was edited',
        ]),
      });
    })
    .catch((error) => {
      console.error(error);
      operationWithError({
        msg: t([
          '9b3f4f3b-7c72-436d-82b7-1d319e7be2e9',
          'There was an error while editing a location',
        ]),
        error,
      });
      throw error;
    });
};

const onCreateLocation = async (props, location, dispatch) => {
  if (location.latitude && location.longitude) {
    location.latitude = parseFloat(location.latitude);
    location.longitude = parseFloat(location.longitude);
  }

  return await props
    .createRippleMakerLocation(location)
    .then(() => {
      operationSuccessful({
        msg: t([
          'ac4ecd79-3f0a-4dd1-b732-80e7532521e2',
          'A location was created',
        ]),
      });
      dispatch(locationCreated(location));
    })
    .catch((error) => {
      operationWithError({
        msg: t([
          'c12ba995-80b0-4256-aed4-84ce08bb76c9',
          'An error has occurred while creating a location',
        ]),
        error,
      });
      throw error;
    });
};

const onNavigateLocation = (props, locationId) => {
  props.navigate(
    locations.ripples.Locations.view.url({ location: locationId })
  );
};

const getLocationWithOrgById = async (props, locationId, organizationId) => {
  const res = await axios.get(
    `/api/locations/${locationId}/withOrg/${organizationId}`,
    getAuthHeaders()
  );
  return res.data.data;
};

const deleteOrganization = async (organization) => {
  return axios
    .delete(`/api/organizations/${organization.id}`, getAuthHeaders())
    .then(() => {
      operationSuccessful({
        msg: t(
          [
            'f779a90b-3cf7-49d6-a5c7-fadabe6fc610',
            'Organization {{org}} was deleted',
          ],
          { org: organization.name }
        ),
      });
    })
    .catch((error) => {
      operationWithError({
        msg: t([
          'a515bff9-41f8-45ac-a8df-871dd045ed13',
          'Organizations with locations or sub organizations cannot be deleted',
        ]),
        error,
      });
      throw error;
    });
};

const deleteLocation = async (location) => {
  return axios
    .delete(
      `/api/locations/${location.id}/org/${location.organizationId}`,
      getAuthHeaders()
    )
    .then(() => {
      operationSuccessful({
        msg: t(
          [
            'cc790d92-d5dd-43d5-95d2-7243fae8df66',
            'Location {{locationName}} was deleted.',
          ],
          {
            locationName: location.name,
          }
        ),
      });
    })
    .catch((error) => {
      operationWithError({
        msg: t([
          '215b321a-645b-4c2c-8ed0-663da31bdd26',
          'Locations with Ripple makers cannot be deleted',
        ]),
        error,
      });
      throw error;
    });
};

const getCurrentScopes = async () => {
  const body = {
    query: LoadScopesQuery,
    variables: {},
  };
  const res = await axios.post('/graphql', body, getAuthHeaders());
  const scopes = get(res, 'data.data.viewer.me.scopes');
  return scopes && scopes.length
    ? scopes.map((scope) => {
        return {
          type: scope.type,
          url: getScopeRoot(scope.scopeId || '').url(),
          wildcard: scope.wildcard,
          label: scope.label,
        };
      })
    : [];
};

const getOrganizationWithLocationsById = async (organizationId) => {
  const res = await axios.get(
    `/api/organizations/${organizationId}/withLocations`,
    getAuthHeaders()
  );
  const organization = res.data.data;
  organization.rippleMakerLocations = organization.locations
    ? organization.locations.map(({ _id, ...rest }) => ({ id: _id, ...rest }))
    : [];

  return organization;
};

const editOrganizationHoc = graphql(editOrganizationMutation, {
  props: ({ mutate }) => ({
    editOrganization: ({
      orgId,
      name,
      googleAnalyticsId,
      features,
      supportDetails,
      inheritContent,
      screenSaver,
      shareContent,
      parentId,
      isPartnerChanged,
    }) =>
      mutate({
        variables: {
          orgId,
          name,
          googleAnalyticsId,
          features,
          supportDetails,
          inheritContent,
          screenSaver,
          shareContent,
          parentId,
          isPartnerChanged,
        },
      }),
  }),
  options: ({ currentOrganizationId }) => ({
    refetchQueries: [{
      query: Query,
      variables: {
        orgId: currentOrganizationId,
        withActiveServiceLevel: true
      }
    }],
  }),
});

const createOrganizationHoc = graphql(createOrganizationMutation, {
  props: ({ mutate }) => ({
    createOrganization: ({
      name,
      googleAnalyticsId,
      features,
      supportDetails,
      inheritContent,
      screenSaver,
      shareContent,
      parentId,
      highSecurityUsers,
    }) =>
      mutate({
        variables: {
          name,
          googleAnalyticsId,
          features,
          supportDetails,
          inheritContent,
          screenSaver,
          shareContent,
          parentId,
          highSecurityUsers,
        },
      }),
  }),
  options: ({ currentOrganizationId }) => ({
    refetchQueries: [{
      query: Query,
      variables: {
        orgId: currentOrganizationId,
        withActiveServiceLevel: true
      }
    }],
  }),
});

const editLocationHoc = graphql(editRippleMakerLocationMutation, {
  props: ({ mutate }) => ({
    editRippleMakerLocation: ({
      id,
      name,
      address,
      displayedName,
      displayedAddress,
      city,
      organization,
      zipCode,
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhoneNumber,
      country,
      state,
      customer,
      latitude,
      longitude,
      locationGroup,
      notes,
      isPrivate,
      isForEvents,
      isWebappEnabled,
      logoUrl,
    }) =>
      mutate({
        variables: {
          id,
          name,
          address,
          displayedName,
          displayedAddress,
          city,
          organization,
          zipCode,
          contactFirstName,
          contactLastName,
          contactEmail,
          contactPhoneNumber,
          country,
          state,
          customer,
          latitude,
          longitude,
          locationGroup,
          notes,
          isPrivate,
          isForEvents,
          isWebappEnabled,
          logoUrl,
        },
      }),
  }),
  options: ({ currentOrganizationId }) => ({
    refetchQueries: [{
      query: Query,
      variables: {
        orgId: currentOrganizationId,
        withActiveServiceLevel: true
      },
    }],
  }),
});

const createLocationHoc = graphql(createRippleMakerLocationMutation, {
  props: ({ mutate }) => ({
    createRippleMakerLocation: ({
      name,
      address,
      displayedName,
      displayedAddress,
      city,
      organization,
      zipCode,
      contactFirstName,
      contactLastName,
      contactEmail,
      contactPhoneNumber,
      country,
      state,
      customer,
      latitude,
      longitude,
      locationGroup,
      notes,
      isPrivate,
      isForEvents,
      isWebappEnabled,
      logoUrl,
    }) =>
      mutate({
        variables: {
          name,
          address,
          displayedName,
          displayedAddress,
          city,
          organization,
          zipCode,
          contactFirstName,
          contactLastName,
          contactEmail,
          contactPhoneNumber,
          country,
          state,
          customer,
          latitude,
          longitude,
          locationGroup,
          notes,
          isPrivate,
          isForEvents,
          isWebappEnabled,
          logoUrl,
        },
      }),
  }),
  options: ({ archived, currentOrganizationId }) => ({
    refetchQueries: [{
      query: Query,
      variables: {
        archived,
        orgId: currentOrganizationId,
        withActiveServiceLevel: true
      },
    }],
  }),
});

const baseHoc = graphql(Query, {
  props: ({ ownProps, data: { loading, viewer } }) => {
    if (!loading && viewer) {
      const countries = viewer.countries;
      const states = viewer.states;
      const organization = viewer.organizationScope.organization;
      const { activeServiceLevel } = organization;
      const { isSkinDesignerEnabled } = organization.features;

      return {
        loading,
        countries,
        states,
        organization,
        activeServiceLevel,
        isSkinDesignerEnabled,
      };
    }
    return { loading };
  },
  options: ({ orgId, withActiveServiceLevel = false }) => {
    return {
      variables: {
        orgId,
        withActiveServiceLevel: withActiveServiceLevel || false,
      },
    };
  },
});

const mapDispatchToProps = (dispatch, props) => {
  return {
    onEditOrganization: (organization) =>
      onEditOrganization(props, organization, dispatch),
    onCreateOrganization: (organization) =>
      onCreateOrganization(props, organization, dispatch),
    onEditLocation: (params) =>
      onEditLocation(props, params, dispatch),
    onCreateLocation: (location) =>
      onCreateLocation(props, location, dispatch),
  };
};

const WithOrganizationAction = (Component) => (props) => {
  return (
    <Component
      onEditOrganization={(organization, dispatch) =>
        onEditOrganization(props, organization, dispatch)}
      onCreateOrganization={(organization, dispatch) =>
        onCreateOrganization(props, organization, dispatch)}
      onCreateLocation={(location, dispatch) =>
        onCreateLocation(props, location, dispatch)}
      onEditLocation={(params, dispatch) =>
        onEditLocation(props, params, dispatch)}
      onNavigateLocation={(locationId) =>
        onNavigateLocation(props, locationId)}
      getLocationWithOrgById={(locationId, locationOrgId) =>
        getLocationWithOrgById(props, locationId, locationOrgId)}
      getOrganizationWithLocationsById={(organizationId) =>
        getOrganizationWithLocationsById(organizationId)}
      getCurrentScopes={getCurrentScopes}
      deleteLocation={deleteLocation}
      deleteOrganization={deleteOrganization}
      {...props}
    />
  )
};

export default (Component) =>
  compose(
    withState('orgId', 'setOrgId', undefined),
    withState('withActiveServiceLevel', 'setWithActiveServiceLevel', false),
    baseHoc,
    editOrganizationHoc,
    createOrganizationHoc,
    editLocationHoc,
    createLocationHoc,
    withNavigate(),
    connect(
      null,
      mapDispatchToProps
    )
  )(WithOrganizationAction(Component));
